<template>
  <div class="home bg-lol-purple text-white min-h-screen font-sans">
    <main>
      <section class="hero relative h-screen flex items-center overflow-hidden">
        <video 
          class="absolute top-0 left-0 min-w-full min-h-full object-cover"
          autoplay 
          loop 
          muted 
          playsinline
        >
          <source src="/videos/hero-background.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="relative z-10 container mx-auto px-4 text-center">
          <h2 class="text-lg md:text-xl font-semibold mb-2 text-lol-pink">NEW COLLECTION</h2>
          <h1 class="text-4xl md:text-6xl font-bold mb-4">lol Photo Booth</h1>
          <p class="text-xl md:text-2xl mb-8">Make your special day even more memorable with premium photo booths!</p>
          <router-link to="/our-products" class="bg-lol-pink hover:bg-opacity-80 text-white font-bold py-2 px-4 md:py-3 md:px-6 rounded-full text-lg">
            BOOK NOW
          </router-link>
        </div>
      </section>

      <section class="py-16 bg-lol-purple">
        <div class="container mx-auto px-4">
          <div class="flex flex-col md:flex-row items-center">
            <div class="md:w-1/2 mb-8 md:mb-0 relative">
              <img src="/images/photobooth-side-1.png" alt="Photo Booth" class="rounded-lg shadow-lg">
              <div class="absolute -bottom-4 -right-4 bg-lol-pink text-white p-4 rounded-lg shadow-lg">
                <p class="font-bold text-lg">Premium Quality</p>
                <p>State-of-the-art technology</p>
              </div>
            </div>
            <div class="md:w-1/2 md:pl-8">
              <h2 class="text-4xl font-bold mb-4 text-lol-pink">Your Memorable Moments, Captured</h2>
              <p class="text-lg mb-6 leading-relaxed">
                Turn your special occasions into lasting memories with lol Photo Booth. Our cutting-edge technology and sleek designs ensure that every moment is captured beautifully. From weddings to parties, our booths are designed to add fun and excitement, making your event truly unforgettable.
              </p>
              <div class="flex items-center space-x-4">
                <div class="bg-lol-pink rounded-full p-2">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
                <p class="font-semibold">High-quality prints</p>
              </div>
              <div class="flex items-center space-x-4 mt-4">
                <div class="bg-lol-pink rounded-full p-2">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
                <p class="font-semibold">Customizable designs</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Our Photo Booths section -->
      <section class="py-12 md:py-16 bg-lol-purple text-white relative">
        <div class="absolute inset-0 bg-cover bg-center opacity-20" style="background-image: url('/bg/party-bg5.png');"></div>
        <div class="container mx-auto px-4 relative z-10">
          <h2 class="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12 text-white">Our Photo Booths</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div class="bg-lol-purple bg-opacity-80 p-6 rounded-lg shadow-lg border border-lol-pink">
              <img src="/images/bronze-booth.jpg" alt="Bronze Booth" class="w-full h-64 object-cover rounded-lg mb-4">
              <h3 class="text-2xl font-bold mb-2 text-lol-pink">Bronze Booth</h3>
              <p class="mb-4">Our classic booth, perfect for any event. Compact and full of fun!</p>
              <router-link to="/bronze-booth" class="bg-lol-pink hover:bg-opacity-80 text-white font-bold py-2 px-4 rounded-full inline-block">
                View Details
              </router-link>
            </div>
            <div class="bg-lol-purple bg-opacity-80 p-6 rounded-lg shadow-lg border border-lol-pink">
              <img src="/images/silver-mirror-booth.jpg" alt="Silver Mirror Booth" class="w-full h-64 object-cover rounded-lg mb-4">
              <h3 class="text-2xl font-bold mb-2 text-lol-pink">Silver Mirror Booth</h3>
              <p class="mb-4">Sleek and stylish, our silver mirror booth adds a touch of elegance to your event.</p>
              <router-link to="/silver-mirror-booth" class="bg-lol-pink hover:bg-opacity-80 text-white font-bold py-2 px-4 rounded-full inline-block">
                View Details
              </router-link>
            </div>
            <div class="bg-lol-purple bg-opacity-80 p-6 rounded-lg shadow-lg border border-lol-pink">
              <img src="/images/gold-mirror-booth.jpg" alt="Gold Mirror Booth" class="w-full h-64 object-cover rounded-lg mb-4">
              <h3 class="text-2xl font-bold mb-2 text-lol-pink">Gold Mirror Booth</h3>
              <p class="mb-4">The ultimate photo experience. Our gold mirror booth is the star of any event.</p>
              <router-link to="/gold-mirror-booth" class="bg-lol-pink hover:bg-opacity-80 text-white font-bold py-2 px-4 rounded-full inline-block">
                View Details
              </router-link>
            </div>
            <div class="bg-lol-purple bg-opacity-80 p-6 rounded-lg shadow-lg border border-lol-pink">
              <img src="/images/retro-booth1.jpeg" alt="Retro Booth" class="w-full h-64 object-cover rounded-lg mb-4">
              <h3 class="text-2xl font-bold mb-2 text-lol-pink">Retro Booth</h3>
              <p class="mb-4">A classic touch for your event. Our retro booth brings a nostalgic feel to your photos.</p>
              <router-link to="/retro-booth" class="bg-lol-pink hover:bg-opacity-80 text-white font-bold py-2 px-4 rounded-full inline-block">
                View Details
              </router-link>
            </div>
          </div>
        </div>
      </section>

      <!-- Testimonials section -->
      <section class="py-16 bg-lol-purple text-white relative">
        <div class="absolute inset-0 bg-cover bg-center z-0 opacity-30" style="background-image: url('/bg/party-bg4.png');"></div>
        <div class="container mx-auto px-4 relative z-10">
          <h2 class="text-4xl font-bold text-center mb-12 text-white">Testimonials</h2>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div class="bg-lol-purple bg-opacity-80 p-6 rounded-lg shadow-lg border border-lol-pink flex flex-col items-center">
              <h3 class="text-lol-pink text-xl font-semibold mb-4">TESTIMONIALS</h3>
              <div class="flex items-center mb-4">
                <svg v-for="i in 5" :key="i" class="w-5 h-5 text-yellow-400 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
              </div>
              <p class="text-center mb-6">We had a blast with lol Photo Booth at our family reunion! The attendant was friendly and helpful, and the selection of props was fantastic. The prints were a hit, and we loved that we could customize the photo strips with our event theme. It added a special touch to our gathering, and everyone left with a memorable keepsake.</p>
              <img src="/images/jane-will.png" alt="Jane" class="w-16 h-16 rounded-full mb-2">
              <p class="font-bold">Jane</p>
            </div>
            
            <div class="bg-lol-purple bg-opacity-80 p-6 rounded-lg shadow-lg border border-lol-pink flex flex-col items-center">
              <h3 class="text-lol-pink text-xl font-semibold mb-4">TESTIMONIALS</h3>
              <div class="flex items-center mb-4">
                <svg v-for="i in 5" :key="i" class="w-5 h-5 text-yellow-400 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
              </div>
              <p class="text-center mb-6">I recently hosted a birthday party and decided to surprise my guests with a photo booth from lol Photo Booth. It was a hit! The booth setup was quick, and the quality of the photos exceeded my expectations. The digital copies were a convenient way to share the memories with friends and family. The entire experience was seamless, from booking to the event day. Thank you for making my party unforgettable!</p>
              <img src="/images/rachel.png" alt="Rachel" class="w-16 h-16 rounded-full mb-2">
              <p class="font-bold">Rachel</p>
            </div>
            
            <div class="bg-lol-purple bg-opacity-80 p-6 rounded-lg shadow-lg border border-lol-pink flex flex-col items-center">
              <h3 class="text-lol-pink text-xl font-semibold mb-4">TESTIMONIALS</h3>
              <div class="flex items-center mb-4">
                <svg v-for="i in 5" :key="i" class="w-5 h-5 text-yellow-400 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
              </div>
              <p class="text-center mb-6">Attended a friend's wedding where lol Photo Booth was set up, and I was impressed! The booth was sleek, and the props were fun. The attendant was engaging and made sure everyone had a great time. The prints were a hit at the reception, and it was a memorable addition to the wedding festivities. Will definitely consider lol Photo Booth for future events!</p>
              <img src="/images/tatyana.png" alt="Tatyana" class="w-16 h-16 rounded-full mb-2">
              <p class="font-bold">Tatyana</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Updated Contact section -->
      <section class="py-16 bg-lol-purple text-white relative">
        <div class="absolute inset-0 bg-cover bg-center filter blur-sm brightness-50" style="background-image: url('/bg/party-bg6.png');"></div>
        <div class="relative z-10 container mx-auto px-4">
          <h2 class="text-4xl font-bold text-center mb-12 text-white">Contact lol Photo Booth</h2>
          <div class="bg-lol-purple bg-opacity-80 p-8 rounded-lg shadow-lg max-w-7xl mx-auto">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div>
                <h3 class="text-2xl font-bold mb-6">Have a Question? Need a Quote?</h3>
                <p class="mb-6 text-lg leading-relaxed space-y-4">
                  <span class="block leading-loose">We're here to help! Whether you have inquiries about our services, want to discuss custom packages, or simply need assistance, feel free to reach out.</span>
                  <span class="block leading-loose">Fill out the form, and we'll get back to you promptly. Your event's success is our priority, and we look forward to making it memorable together.</span>
                </p>
              </div>
              <form @submit.prevent="submitForm" class="space-y-6">
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <input v-model="form.firstName" type="text" placeholder="First Name" class="w-full p-3 rounded bg-white text-lol-purple" required>
                  <input v-model="form.lastName" type="text" placeholder="Last Name" class="w-full p-3 rounded bg-white text-lol-purple" required>
                </div>
                <input v-model="form.email" type="email" placeholder="Email Address" class="w-full p-3 rounded bg-white text-lol-purple" required>
                <input v-model="form.subject" type="text" placeholder="Subject" class="w-full p-3 rounded bg-white text-lol-purple" required>
                <textarea v-model="form.message" placeholder="Your Message" rows="5" class="w-full p-3 rounded bg-white text-lol-purple" required></textarea>
                <MathChallenge id="home-math-challenge" @update:isValid="updateMathChallengeValidity" />
                <button 
                  type="submit" 
                  class="bg-lol-pink hover:bg-opacity-80 text-white font-bold py-3 px-6 rounded-full text-lg w-full sm:w-auto"
                  :disabled="!isMathChallengeValid"
                >
                  SUBMIT FORM
                </button>
              </form>
            </div>
          </div>
        </div>
        <!-- 添加提交状态消息 -->
        <div v-if="submitStatus" class="mt-4 text-center text-xl font-bold" :class="{ 'text-green-400': submitStatus === 'success', 'text-red-400': submitStatus === 'error' }">
          {{ statusMessage }}
        </div>
      </section>

      <!-- New Features section -->
      <section class="py-16 bg-lol-purple text-white">
        <div class="container mx-auto px-4">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div class="text-center">
              <div class="mb-4">
                <svg class="w-12 h-12 mx-auto text-lol-pink" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                </svg>
              </div>
              <h3 class="text-xl font-bold mb-2">Premium Setup</h3>
              <p class="text-sm">
                Elevate your event with our premium setup services, ensuring every detail is perfect for your guests' photo booth experience.
              </p>
            </div>
            <div class="text-center">
              <div class="mb-4">
                <svg class="w-12 h-12 mx-auto text-lol-pink" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
                </svg>
              </div>
              <h3 class="text-xl font-bold mb-2">High Quality Booths</h3>
              <p class="text-sm">
                Choose from our High Quality Booths to take the perfect snap of your moment to save it as your best memory.
              </p>
            </div>
            <div class="text-center">
              <div class="mb-4">
                <svg class="w-12 h-12 mx-auto text-lol-pink" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"></path>
                </svg>
              </div>
              <h3 class="text-xl font-bold mb-2">Real-Time Support</h3>
              <p class="text-sm">
                Receive real-time support and assistance throughout your event, ensuring that everything runs smoothly and your guests have an amazing time with lol Photo Booth.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import seoMixin from '@/mixins/seoMixin'
import axios from 'axios'
import MathChallenge from '@/components/MathChallenge.vue'

export default {
  name: 'Home',
  mixins: [seoMixin],
  components: {
    MathChallenge
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: ''
      },
      submitStatus: null,
      statusMessage: '',
      isMathChallengeValid: false
    }
  },
  mounted() {
    this.setMetaTags(
      'LOL Photo Booth - Premium Photo Booth Rentals in Melbourne',
      'Melbourne\'s leading photo booth rental service. Perfect for weddings, birthday parties and special events. Create lasting memories with our premium photo booths.'
    )
  },
  methods: {
    updateMathChallengeValidity(isValid) {
      this.isMathChallengeValid = isValid
    },
    async submitForm() {
      if (!this.isMathChallengeValid) {
        this.submitStatus = 'error';
        this.statusMessage = 'Please complete the security check correctly.';
        return;
      }
      try {
        const response = await axios.post('/api/submit-contact', this.form);
        if (response.data.success) {
          this.submitStatus = 'success';
          this.statusMessage = 'Your message has been sent successfully!';
          this.resetForm();
        } else {
          throw new Error('Submission failed');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        this.submitStatus = 'error';
        this.statusMessage = 'There was an error sending your message. Please try again.';
      }
    },
    resetForm() {
      this.form = {
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: ''
      };
    }
  }
}
</script>

<style scoped>
.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(41, 34, 60, 0.5);
  z-index: 1;
}

.filter {
  backdrop-filter: blur(4px) brightness(50%);
}
</style>
