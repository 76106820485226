<template>
  <div class="step-4-addons">
    <h2 class="text-2xl font-bold mb-4">Select Add-ons</h2>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div v-for="addon in addons" :key="addon.id" class="addon-option bg-lol-purple bg-opacity-50 p-4 rounded-lg">
        <label class="flex flex-col items-center cursor-pointer">
          <img :src="addon.image" :alt="addon.name" class="w-full h-auto mb-4 rounded-lg">
          <input 
            type="checkbox" 
            :value="addon.id" 
            v-model="selectedAddons"
            class="mb-2"
            @change="updateBookingData"
          >
          <div class="text-center">
            <h3 class="text-lg font-semibold">{{ addon.name }}</h3>
            <p class="text-sm text-gray-300">{{ addon.description }}</p>
            <p class="text-lol-pink font-bold mt-2">${{ addon.price }}</p>
          </div>
        </label>
      </div>
    </div>
    <div class="mt-6">
      <h3 class="text-xl font-bold">Total Add-ons: ${{ totalAddonPrice }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step4Addons',
  props: ['bookingData'],
  data() {
    return {
      selectedAddons: [],
      addons: [
        { 
          id: 1, 
          name: 'Red Carpet & Golden Pillars', 
          price: 150, 
          description: 'Red carpet & golden pillars on both sides for a grand entrance.', 
          image: '/images/addons/addon1.jpeg' 
        },
        { 
          id: 2, 
          name: 'Photobooth Album', 
          price: 75, 
          description: 'The photobooth album captures memories in lasting images.', 
          image: '/images/addons/addon2.jpeg' 
        },
        { 
          id: 3, 
          name: '1-meter LED Lights', 
          price: 350, 
          description: '1-meter high LED lights, adding more backdrop to your big day.', 
          image: '/images/addons/addon3.jpeg' 
        },
      ]
    }
  },
  computed: {
    totalAddonPrice() {
      return this.selectedAddons.reduce((total, addonId) => {
        const addon = this.addons.find(a => a.id === addonId)
        return total + addon.price
      }, 0)
    }
  },
  methods: {
    updateBookingData() {
      this.$emit('update:booking-data', { 
        addons: this.selectedAddons,
        addonsTotalPrice: this.totalAddonPrice
      })
    }
  },
  mounted() {
    if (this.bookingData.addons) {
      this.selectedAddons = this.bookingData.addons
    }
  }
}
</script>

<style scoped>
.addon-option:hover {
  background-color: rgba(41, 34, 60, 0.7);
}
</style>
