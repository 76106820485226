<template>
  <div class="min-h-screen bg-lol-purple py-16">
    <div class="container mx-auto px-4">
      <h1 class="text-5xl font-bold text-center text-white mb-4">Our Photo Booths</h1>
      <p class="text-center text-white text-xl mb-12">Choose the perfect photo booth for your special event</p>
      
      <div class="max-w-4xl mx-auto space-y-8">
        <PhotoBoothCard
          v-for="booth in booths"
          :key="booth.type"
          :booth-type="booth.type"
          :booth-image="booth.image"
          :booth-name="booth.name"
          :description="booth.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PhotoBoothCard from '@/components/PhotoBoothCard.vue'
import seoMixin from '@/mixins/seoMixin'

export default {
  name: 'OurProducts',
  components: {
    PhotoBoothCard
  },
  mixins: [seoMixin],
  mounted() {
    this.setMetaTags(
      'Photo Booth Rentals Melbourne - Mirror Booths & Classic Booths | LOL Photo Booth',
      'Choose from our premium selection of photo booths in Melbourne. Mirror booths, classic booths and retro booths available for weddings, parties and events.'
    )
  },
  data() {
    return {
      booths: [
        {
          type: 'bronze',
          name: 'Bronze Booth',
          image: '/images/bronze-booth.jpg',
          description: 'Our classic booth, perfect for any event. Compact and full of fun!'
        },
        {
          type: 'silver',
          name: 'Silver Mirror Booth',
          image: '/images/silver-mirror-booth.jpg',
          description: 'Sleek and stylish, our silver mirror booth adds a touch of elegance to your event.'
        },
        {
          type: 'gold',
          name: 'Gold Mirror Booth',
          image: '/images/gold-mirror-booth.jpg',
          description: 'The ultimate photo experience. Our gold mirror booth is the star of any event.'
        },
        {
          type: 'retro',
          name: 'Retro Booth',
          image: '/images/retro-booth1.jpeg',
          description: 'A classic touch for your event. Our retro booth brings a nostalgic feel to your photos.'
        }
      ]
    }
  }
}
</script>
