<template>
  <div class="about-us text-white min-h-screen bg-cover bg-center bg-fixed relative" :style="{ backgroundImage: 'url(/bg/party-bg3.png)' }">
    <!-- 添加一个半透明的遮罩层来降低背景亮度 -->
    <div class="absolute inset-0 bg-black opacity-50"></div>
    
    <div class="container mx-auto px-4 py-16 relative z-10">
      <h1 class="text-5xl font-bold text-center mb-12 animate-fade-in">About Us</h1>
      
      <div class="space-y-8">
        <div class="bg-lol-purple bg-opacity-80 p-8 rounded-lg shadow-lg transition-all duration-300 hover:bg-opacity-90 animate-slide-up">
          <h2 class="text-3xl font-bold mb-4">Welcome to lol Photo Booth</h2>
          <p class="mb-4 leading-relaxed">
            We turn moments into memories and events into experiences. We are a passionate team dedicated to adding fun and excitement to your special occasions through our premium photo booth services.
          </p>
        </div>

        <div class="bg-lol-purple bg-opacity-80 p-8 rounded-lg shadow-lg transition-all duration-300 hover:bg-opacity-90 animate-slide-up">
          <h2 class="text-3xl font-bold mb-4">Our Mission</h2>
          <p class="mb-4 leading-relaxed">
            At lol Photo Booth, our mission is simple: to create unforgettable moments and lasting memories for you and your guests. We believe that every event, whether a wedding, birthday celebration, corporate gathering, or any special occasion, deserves a touch of magic and fun. With our state-of-the-art booths and personalized services, we aim to exceed your expectations and make your event truly memorable.
          </p>
        </div>

        <div class="bg-lol-purple bg-opacity-80 p-8 rounded-lg shadow-lg transition-all duration-300 hover:bg-opacity-90 animate-slide-up">
          <h2 class="text-3xl font-bold mb-4">Why Choose Us?</h2>
          <ul class="list-disc list-inside space-y-4">
            <li><span class="font-bold">Experience:</span> With years of experience in the industry, we know how to make your event stand out and leave a lasting impression.</li>
            <li><span class="font-bold">Cutting-Edge Technology:</span> Our photo booths feature cutting-edge technology, ensuring high-quality prints and a seamless experience for you and your guests.</li>
            <li><span class="font-bold">Customization:</span> We offer a range of customization options, from backdrops to print designs, to match your event's theme and style perfectly.</li>
            <li><span class="font-bold">Professionalism:</span> Our team of professionals is dedicated to providing excellent service from start to finish, making sure every detail is taken care of for a stress-free experience.</li>
            <li><span class="font-bold">Client Satisfaction:</span> Your satisfaction is our priority. We work closely with you to understand your needs and deliver results that exceed your expectations.</li>
          </ul>
        </div>

        <div class="bg-lol-purple bg-opacity-80 p-8 rounded-lg shadow-lg transition-all duration-300 hover:bg-opacity-90 animate-slide-up">
          <h2 class="text-3xl font-bold mb-4">Our Services</h2>
          <ul class="list-disc list-inside space-y-4">
            <li><span class="font-bold">Event Rentals:</span> Whether it's a wedding, birthday, corporate event, or any special gathering, we provide photo booth rentals tailored to your event's needs.</li>
            <li><span class="font-bold">Custom Packages:</span> We offer customizable packages to suit your budget and requirements, ensuring you get the most value for your investment.</li>
            <li><span class="font-bold">On-Site Attendant:</span> Our friendly and professional on-site attendants ensure everything runs smoothly during your event, allowing you to relax and enjoy the experience.</li>
          </ul>
        </div>

        <div class="bg-lol-purple bg-opacity-80 p-8 rounded-lg shadow-lg transition-all duration-300 hover:bg-opacity-90 animate-slide-up">
          <h2 class="text-3xl font-bold mb-4">Get In Touch</h2>
          <p class="mb-4 leading-relaxed">
            Ready to elevate your event with lol Photo Booth? Contact us today to discuss your upcoming event, request a quote, or learn more about our services. We're excited to be part of your celebration and help create memories that last a lifetime.
          </p>
          <router-link to="/contact-us" class="bg-lol-pink hover:bg-opacity-80 text-white font-bold py-3 px-6 rounded-full transition-all duration-300 transform hover:scale-105">
            CONTACT US
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import enhancedSeoMixin from '@/mixins/enhancedSeoMixin'

export default {
  name: 'AboutUs',
  mixins: [enhancedSeoMixin],
  mounted() {
    this.setEnhancedMetaTags({
      title: 'About LOL Photo Booth | Premium Photo Booth Service Melbourne',
      description: 'Learn about LOL Photo Booth, Melbourne\'s leading photo booth rental service. Discover our commitment to quality and creating memorable experiences.',
      keywords: 'photo booth melbourne, about us, photo booth service, event photography melbourne, photo booth rental company, melbourne events',
      image: '/images/about-us.jpg'
    })
  }
}
</script>

<style scoped>
.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.animate-slide-up {
  animation: slideUp 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
</style>
