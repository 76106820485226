<template>
  <div class="step-3-backdrop">
    <h2 class="text-2xl font-bold mb-4">Choose Your Backdrop</h2>
    <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
      <div v-for="backdrop in backdrops" :key="backdrop.id" class="backdrop-option">
        <input 
          type="radio" 
          :id="backdrop.id" 
          :value="backdrop.id" 
          v-model="selectedBackdrop"
          class="hidden"
          @change="updateBookingData"
        >
        <label :for="backdrop.id" class="cursor-pointer">
          <img 
            :src="backdrop.image" 
            :alt="backdrop.name" 
            class="w-full h-auto object-cover rounded-lg transition-all duration-300"
            :class="{ 'ring-4 ring-lol-pink': selectedBackdrop === backdrop.id }"
          >
          <p class="mt-2 text-center">{{ backdrop.name }}</p>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step3Backdrop',
  props: ['bookingData'],
  data() {
    return {
      selectedBackdrop: null,
      backdrops: [
        { id: 1, name: 'Backdrop 1', image: '/images/backdrops/backdrop1.jpeg' },
        { id: 2, name: 'Backdrop 2', image: '/images/backdrops/backdrop2.jpeg' },
        { id: 3, name: 'Backdrop 3', image: '/images/backdrops/backdrop3.jpeg' },
        { id: 4, name: 'Backdrop 4', image: '/images/backdrops/backdrop4.jpeg' },
        { id: 5, name: 'Backdrop 5', image: '/images/backdrops/backdrop5.jpeg' },
        { id: 6, name: 'Backdrop 6', image: '/images/backdrops/backdrop6.jpeg' },
        { id: 7, name: 'Backdrop 7', image: '/images/backdrops/backdrop7.jpeg' },
        { id: 8, name: 'Backdrop 8', image: '/images/backdrops/backdrop8.jpeg' },
      ]
    }
  },
  methods: {
    updateBookingData() {
      this.$emit('update:booking-data', { backdrop: this.selectedBackdrop })
    }
  },
  mounted() {
    if (this.bookingData.backdrop) {
      this.selectedBackdrop = this.bookingData.backdrop
    }
  }
}
</script>

<style scoped>
.backdrop-option img:hover {
  transform: scale(1.05);
}
</style>
