import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import GoldMirrorBooth from '../views/GoldMirrorBooth.vue'
import SilverMirrorBooth from '../views/SilverMirrorBooth.vue'
import RetroBooth from '../views/RetroBooth.vue'
import BronzeBooth from '../views/BronzeBooth.vue'
import OurProducts from '../views/OurProducts.vue'
import Gallery from '../views/Gallery.vue'
import AboutUs from '../views/AboutUs.vue'
import ContactUs from '../views/ContactUs.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/gold-mirror-booth',
    name: 'GoldMirrorBooth',
    component: GoldMirrorBooth
  },
  {
    path: '/silver-mirror-booth',
    name: 'SilverMirrorBooth',
    component: SilverMirrorBooth
  },
  {
    path: '/retro-booth',
    name: 'RetroBooth',
    component: RetroBooth
  },
  {
    path: '/bronze-booth',
    name: 'BronzeBooth',
    component: BronzeBooth
  },
  {
    path: '/our-products',
    name: 'OurProducts',
    component: OurProducts
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/booking-confirmation',
    name: 'BookingConfirmation',
    component: () => import('../views/BookingConfirmation.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
