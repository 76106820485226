<template>
  <header class="bg-lol-purple py-4">
    <div class="container mx-auto flex items-center justify-between px-4">
      <div class="logo flex items-center">
        <img src="/images/IOI-LOGO-100x53.png" alt="lol Photo Booth" class="h-8 md:h-12 w-auto mr-2 md:mr-4">
        <h1 class="text-lg md:text-2xl font-bold text-white">Laugh Out Loud Photo Booth</h1>
      </div>
      <nav class="hidden md:block">
        <ul class="flex space-x-6">
          <li><router-link to="/" class="text-white hover:text-lol-pink">HOME</router-link></li>
          <li><router-link to="/our-products" class="text-white hover:text-lol-pink">OUR PRODUCTS</router-link></li>
          <li><router-link to="/gallery" class="text-white hover:text-lol-pink">GALLERY</router-link></li>
          <li><router-link to="/about-us" class="text-white hover:text-lol-pink">ABOUT US</router-link></li>
          <li><router-link to="/contact-us" class="text-white hover:text-lol-pink">CONTACT</router-link></li>
        </ul>
      </nav>
      <button @click="toggleMenu" class="md:hidden text-white">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path v-if="!isMenuOpen" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </div>

    <div v-if="isMenuOpen" class="md:hidden fixed inset-0 bg-lol-purple z-50 top-16">
      <nav class="container mx-auto px-4 py-4">
        <ul class="space-y-4">
          <li><router-link @click="closeMenu" to="/" class="block text-white hover:text-lol-pink py-2">HOME</router-link></li>
          <li><router-link @click="closeMenu" to="/our-products" class="block text-white hover:text-lol-pink py-2">OUR PRODUCTS</router-link></li>
          <li><router-link @click="closeMenu" to="/gallery" class="block text-white hover:text-lol-pink py-2">GALLERY</router-link></li>
          <li><router-link @click="closeMenu" to="/about-us" class="block text-white hover:text-lol-pink py-2">ABOUT US</router-link></li>
          <li><router-link @click="closeMenu" to="/contact-us" class="block text-white hover:text-lol-pink py-2">CONTACT</router-link></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      isMenuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
      document.body.style.overflow = this.isMenuOpen ? 'hidden' : ''
    },
    closeMenu() {
      this.isMenuOpen = false
      document.body.style.overflow = ''
    }
  }
}
</script>
