<template>
  <div class="step-1-booth-info">
    <h2 class="text-2xl font-bold mb-4">{{ boothType }} Details</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
      <template v-if="boothType === 'Gold Mirror Booth'">
        <div class="booth-image">
          <img src="/images/gold-mirror-booth.jpg" alt="Gold Mirror Booth" class="rounded-lg shadow-lg w-full h-64 object-cover cursor-pointer" @click="openFullImage('/images/gold-mirror-booth.jpg')">
        </div>
      </template>
      <template v-else-if="boothType === 'Silver Mirror Booth'">
        <div class="booth-image">
          <img src="/images/silver-mirror-booth.jpg" alt="Silver Mirror Booth" class="rounded-lg shadow-lg w-full h-64 object-cover cursor-pointer" @click="openFullImage('/images/silver-mirror-booth.jpg')">
        </div>
      </template>
      <template v-else-if="boothType === 'Retro Booth'">
        <div class="booth-image">
          <img src="/images/retro-booth1.jpeg" alt="Retro Booth 1" class="rounded-lg shadow-lg w-full h-64 object-cover cursor-pointer" @click="openFullImage('/images/retro-booth1.jpeg')">
        </div>
        <div class="booth-image">
          <img src="/images/retro-booth2.jpeg" alt="Retro Booth 2" class="rounded-lg shadow-lg w-full h-64 object-cover cursor-pointer" @click="openFullImage('/images/retro-booth2.jpeg')">
        </div>
      </template>
      <template v-else-if="boothType === 'Bronze Booth'">
        <div class="booth-image">
          <img src="/images/bronze-booth.jpg" alt="Bronze Booth" class="rounded-lg shadow-lg w-full h-64 object-cover cursor-pointer" @click="openFullImage('/images/bronze-booth.jpg')">
        </div>
      </template>
    </div>
    <div class="booth-details">
      <template v-if="boothType === 'Gold Mirror Booth'">
        <p class="mb-4">
          Introducing the Gold Mirror Booth – the epitome of luxury and interactive entertainment for your event! This stunning booth combines elegance with cutting-edge technology to create an unforgettable photo experience for you and your guests.
        </p>
        <p class="mb-4">
          With its sleek, gold-framed design, the Gold Mirror Booth becomes an instant focal point, adding a touch of glamour to any venue. It's not just a photo booth; it's a statement piece that elevates the atmosphere of your entire event.
        </p>
        <h3 class="text-xl font-bold mb-2">Features:</h3>
        <ul class="list-disc list-inside mb-4">
          <li>Elegant gold-framed design that complements any event decor</li>
          <li>High-quality DSLR camera for crystal-clear photos</li>
          <li>Large, intuitive touchscreen interface for easy operation</li>
          <li>Customizable digital props and filters to match your event theme</li>
          <li>Instant printing with high-quality photo strips</li>
          <li>Social media sharing capabilities for immediate online posting</li>
          <li>Spacious setup to accommodate group photos</li>
          <li>Professional lighting system for perfect shots every time</li>
        </ul>
        <p class="mb-4">
          The Gold Mirror Booth is more than just a photo opportunity – it's an interactive experience that will keep your guests entertained throughout the event. From corporate galas to weddings, this booth adds a touch of sophistication and fun to any occasion.
        </p>
        <p class="mb-4">
          Give your guests a memento they'll treasure. With the Gold Mirror Booth, every snapshot becomes a work of art, beautifully framed and instantly shareable. Make your event shine with the unparalleled elegance of the Gold Mirror Booth!
        </p>
      </template>
      <template v-else-if="boothType === 'Silver Mirror Booth'">
        <p class="mb-4">
          Introducing the Silver Mirror Booth – designed to elevate interactive fun! Featuring a sleek curved mirror and paired with a detachable flash, this booth makes your photo experience even more exciting. Add a touch of innovation and joy to your event with the Silver Mirror Booth.
        </p>
        <p class="mb-4">
          Don't miss out on creating unforgettable memories with this extraordinary booth!
        </p>
        <h3 class="text-xl font-bold mb-2">Features:</h3>
        <ul class="list-disc list-inside mb-4">
          <li>User-friendly touch screen technology</li>
          <li>Professional-quality photos, photo selection and photo retake capabilities, ensuring your guests look and feel their best!</li>
          <li>Delivery + Setup + Unpack.</li>
          <li>A friendly and helpful attendant.</li>
          <li>40+ props and other creative tools.</li>
          <li>Unlimited usage</li>
          <li>Custom designed photo strip</li>
          <li>GIF technology</li>
          <li>Original Images provided post-event via online</li>
        </ul>
      </template>
      <template v-else-if="boothType === 'Retro Booth'">
        <p class="mb-4">
          Introducing the Retro Booth – your gateway to a timeless photo experience! This stylish booth perfectly blends vintage charm with modern technology, capturing the essence of your event in a classic way. Designed to bring a nostalgic vibe while offering the flexibility to capture every guest in beautifully crafted photos, the Retro Booth is sure to be a hit.
        </p>
        <p class="mb-4">
          With just one simple click, your guests can enjoy printed keepsakes in seconds. Add a touch of old-school fun and elegance to your event with the Retro Booth. It's the perfect complement to your retro-themed event or wedding, infusing your venue with a special vintage atmosphere.
        </p>
        <p class="mb-4">
          Retro Booth 配合您喜欢的复古风，让活动/婚礼现场充满了特别的韵味。
        </p>
        <h3 class="text-xl font-bold mb-2">Features:</h3>
        <ul class="list-disc list-inside mb-4">
          <li>Delivery, setup, and unpacking</li>
          <li>A friendly and helpful attendant</li>
          <li>Over 40 props and creative tools</li>
          <li>Unlimited usage</li>
          <li>Custom designed photo strips</li>
          <li>GIF technology</li>
          <li>Original images provided post-event via online gallery</li>
        </ul>
      </template>
      <template v-else-if="boothType === 'Bronze Booth'">
        <p class="mb-4">
          Introducing the Bronze Booth – your ultimate photo experience! This versatile booth iPad screen can be moved both horizontally and vertically, allowing you to fit as many guests as possible into one memorable photo. With just one click, your guests will receive a printed copy of their photo in seconds. Add a touch of fun and flexibility to your event with the Bronze Booth. Don't miss out on creating unforgettable memories with this amazing booth!
        </p>
        <h3 class="text-xl font-bold mb-2">Features:</h3>
        <ul class="list-disc list-inside mb-4">
          <li>User-friendly iPad screen</li>
          <li>Professional-quality photos, photo selection and photo retake capabilities, ensuring your guests look and feel their best!</li>
          <li>Delivery + Setup + Unpack.</li>
          <li>A friendly and helpful attendant.</li>
          <li>40+ props and other creative tools.</li>
          <li>Unlimited usage</li>
          <li>Custom designed photo strip</li>
          <li>GIF technology</li>
          <li>Original Images provided post-event via online</li>
        </ul>
      </template>
    </div>
    <!-- 添加全屏图片模态框 -->
    <div v-if="showFullImage" class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" @click="closeFullImage">
      <img :src="fullImageSrc" alt="Full size image" class="max-w-full max-h-full object-contain">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step1BoothInfo',
  props: {
    boothType: {
      type: String,
      required: true,
      validator: (value) => ['Gold Mirror Booth', 'Silver Mirror Booth', 'Retro Booth', 'Bronze Booth'].includes(value)
    }
  },
  data() {
    return {
      showFullImage: false,
      fullImageSrc: ''
    }
  },
  methods: {
    openFullImage(src) {
      this.fullImageSrc = src;
      this.showFullImage = true;
    },
    closeFullImage() {
      this.showFullImage = false;
      this.fullImageSrc = '';
    }
  }
}
</script>

<style scoped>
.booth-image img {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
}
</style>
