<template>
  <div class="math-challenge">
    <label :for="id" class="block text-lg mb-2">Security Check: {{ num1 }} + {{ num2 }} = ?</label>
    <input
      :id="id"
      v-model="userAnswer"
      type="number"
      class="w-full p-3 rounded bg-white text-lol-purple"
      required
      @input="$emit('update:isValid', checkAnswer())"
    >
  </div>
</template>

<script>
export default {
  name: 'MathChallenge',
  props: {
    id: {
      type: String,
      default: 'math-challenge'
    }
  },
  data() {
    return {
      num1: 0,
      num2: 0,
      userAnswer: ''
    }
  },
  mounted() {
    this.generateChallenge()
  },
  methods: {
    generateChallenge() {
      this.num1 = Math.floor(Math.random() * 10)
      this.num2 = Math.floor(Math.random() * 10)
    },
    checkAnswer() {
      return parseInt(this.userAnswer) === (this.num1 + this.num2)
    }
  }
}
</script>
