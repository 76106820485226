export default {
  methods: {
    setEnhancedMetaTags({ title, description, keywords, image }) {
      // 基本 meta 标签
      document.title = title
      document.querySelector('meta[name="description"]').setAttribute('content', description)
      
      // 更新关键词
      let keywordsTag = document.querySelector('meta[name="keywords"]')
      if (keywordsTag) {
        keywordsTag.setAttribute('content', keywords)
      }

      // 更新 Open Graph 标签
      let ogTitle = document.querySelector('meta[property="og:title"]')
      let ogDesc = document.querySelector('meta[property="og:description"]')
      let ogImage = document.querySelector('meta[property="og:image"]')

      if (ogTitle) ogTitle.setAttribute('content', title)
      if (ogDesc) ogDesc.setAttribute('content', description)
      if (ogImage && image) ogImage.setAttribute('content', image)
    }
  }
} 