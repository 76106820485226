<template>
  <div class="contact-us bg-lol-purple text-white min-h-screen bg-cover bg-center bg-fixed relative" :style="{ backgroundImage: 'url(/bg/party-bg2.png)' }">
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div class="container mx-auto px-4 py-16 relative z-10">
      <h1 class="text-5xl font-bold text-center mb-12 animate-fade-in">Contact Us</h1>
      
      <div class="max-w-2xl mx-auto bg-lol-purple bg-opacity-80 p-8 rounded-lg shadow-lg animate-slide-up">
        <form @submit.prevent="submitForm" class="space-y-6">
          <div>
            <label for="name" class="block text-lg mb-2">Name</label>
            <input type="text" id="name" v-model="form.name" required class="w-full p-2 rounded bg-white text-lol-purple">
          </div>
          <div>
            <label for="email" class="block text-lg mb-2">Email</label>
            <input type="email" id="email" v-model="form.email" required class="w-full p-2 rounded bg-white text-lol-purple">
          </div>
          <div>
            <label for="phone" class="block text-lg mb-2">Phone</label>
            <input type="tel" id="phone" v-model="form.phone" class="w-full p-2 rounded bg-white text-lol-purple">
          </div>
          <div>
            <label for="message" class="block text-lg mb-2">Message</label>
            <textarea id="message" v-model="form.message" rows="4" required class="w-full p-2 rounded bg-white text-lol-purple"></textarea>
          </div>
          <MathChallenge id="contact-math-challenge" @update:isValid="updateMathChallengeValidity" />
          <button 
            type="submit" 
            class="bg-lol-pink hover:bg-opacity-80 text-white font-bold py-3 px-6 rounded-full transition-all duration-300 transform hover:scale-105"
            :disabled="!isMathChallengeValid"
          >
            Send Message
          </button>
        </form>
      </div>

      <div v-if="submitStatus" class="mt-8 text-center text-xl font-bold" :class="{ 'text-green-400': submitStatus === 'success', 'text-red-400': submitStatus === 'error' }">
        {{ statusMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MathChallenge from '@/components/MathChallenge.vue'

export default {
  name: 'ContactUs',
  components: {
    MathChallenge
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        message: ''
      },
      submitStatus: null,
      statusMessage: '',
      isMathChallengeValid: false
    }
  },
  methods: {
    updateMathChallengeValidity(isValid) {
      this.isMathChallengeValid = isValid
    },
    async submitForm() {
      if (!this.isMathChallengeValid) {
        this.submitStatus = 'error';
        this.statusMessage = 'Please complete the security check correctly.';
        return;
      }
      try {
        const response = await axios.post('/api/submit-contact', this.form);
        if (response.data.success) {
          this.submitStatus = 'success';
          this.statusMessage = 'Your message has been sent successfully!';
          this.resetForm();
          // 3秒后跳转到首页
          setTimeout(() => {
            this.$router.push('/');
          }, 3000);
        } else {
          throw new Error('Submission failed');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        this.submitStatus = 'error';
        this.statusMessage = 'There was an error sending your message. Please try again.';
      }
    },
    resetForm() {
      this.form = {
        name: '',
        email: '',
        phone: '',
        message: ''
      };
      this.isMathChallengeValid = false;
    }
  }
}
</script>

<style scoped>
.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.animate-slide-up {
  animation: slideUp 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
</style>
