<template>
  <div class="booth-card">
    <div class="image-container" :class="boothType">
      <img 
        :src="boothImage" 
        :alt="boothName"
        class="booth-image"
      />
    </div>
    <div class="content-container">
      <h3 class="text-lol-pink text-3xl font-bold mb-4">{{ boothName }}</h3>
      <p class="text-white text-xl mb-6">{{ description }}</p>
      <div class="button-container">
        <router-link 
          :to="getBoothRoute"
          class="view-details-btn hover:scale-105 transition-transform"
        >
          View Details
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhotoBoothCard',
  props: {
    boothType: {
      type: String,
      required: true
    },
    boothImage: {
      type: String,
      required: true
    },
    boothName: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  computed: {
    getBoothRoute() {
      const routes = {
        'bronze': '/bronze-booth',
        'silver': '/silver-mirror-booth',
        'gold': '/gold-mirror-booth',
        'retro': '/retro-booth'
      }
      return routes[this.boothType] || '/'
    }
  }
}
</script>

<style scoped>
.booth-card {
  @apply bg-lol-purple rounded-xl overflow-hidden flex flex-col md:flex-row;
  min-height: 400px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.image-container {
  @apply bg-white overflow-hidden flex-shrink-0;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

@media (min-width: 768px) {
  .image-container {
    width: 50%;
    height: auto;
  }
}

.content-container {
  @apply p-8 flex flex-col justify-center flex-grow;
}

.button-container {
  @apply flex;
}

.booth-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* 针对特定产品的图片调整 */
.bronze .booth-image {
  transform: scale(0.95);
}

.silver .booth-image {
  transform: scale(0.9);
}

.gold .booth-image {
  transform: scale(1);
}

.retro .booth-image {
  transform: scale(0.95);
}

.view-details-btn {
  @apply bg-lol-pink text-white px-8 py-3 rounded-full font-semibold;
  display: inline-block;
  text-decoration: none;
}
</style> 