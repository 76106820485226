<template>
  <div class="step-2-rental-hours">
    <h2 class="text-2xl font-bold mb-4">Select Rental Hours</h2>
    <div class="mb-4">
      <label for="hours" class="block mb-2">Rental Duration:</label>
      <select id="hours" v-model="selectedOption" class="w-full p-2 bg-white text-lol-purple rounded" @change="handleOptionChange">
        <option v-if="bookingData.boothType === 'Bronze Booth'" value="3">3 hours - $750</option>
        <option v-if="bookingData.boothType === 'Bronze Booth'" value="4">4 hours - $900</option>
        <option v-if="bookingData.boothType === 'Silver Mirror Booth'" value="3">3 hours - $850</option>
        <option v-if="bookingData.boothType === 'Silver Mirror Booth'" value="4">4 hours - $1000</option>
        <option v-if="bookingData.boothType === 'Gold Mirror Booth'" value="3">3 hours - $1050</option>
        <option v-if="bookingData.boothType === 'Gold Mirror Booth'" value="4">4 hours - $1200</option>
        <option v-if="bookingData.boothType === 'Retro Booth'" value="4">4 hours - $1200</option>
        <option value="more">More than 4 hours</option>
      </select>
    </div>
    <p v-if="selectedOption !== 'more'" class="mb-4 font-bold">Total: ${{ totalPrice }}</p>
    <div v-if="showContactForm" class="mt-4">
      <p class="mb-4">For bookings over 4 hours, please contact us for a custom quote.</p>
      <button @click="openContactForm" class="bg-lol-pink hover:bg-opacity-80 text-white font-bold py-2 px-4 rounded-full">
        Contact Us
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step2RentalHours',
  props: ['bookingData'],
  data() {
    return {
      selectedOption: '4',
      showContactForm: false
    }
  },
  computed: {
    totalPrice() {
      if (this.bookingData.boothType === 'Bronze Booth') {
        return this.selectedOption === '3' ? 750 : 900;
      } else if (this.bookingData.boothType === 'Silver Mirror Booth') {
        return this.selectedOption === '3' ? 850 : 1000;
      } else if (this.bookingData.boothType === 'Gold Mirror Booth') {
        return this.selectedOption === '3' ? 1050 : 1200;
      } else {
        return 1200; // Retro Booth
      }
    }
  },
  methods: {
    handleOptionChange() {
      this.showContactForm = this.selectedOption === 'more';
      if (this.selectedOption !== 'more') {
        this.$emit('update:booking-data', { hours: parseInt(this.selectedOption) })
      }
    },
    openContactForm() {
      this.$emit('open-contact-form')
    }
  },
  mounted() {
    // Set default option based on booth type
    if (this.bookingData.boothType === 'Bronze Booth' || this.bookingData.boothType === 'Silver Mirror Booth' || this.bookingData.boothType === 'Gold Mirror Booth') {
      this.selectedOption = '3';
    } else {
      this.selectedOption = '4';
    }
    this.$emit('update:booking-data', { hours: parseInt(this.selectedOption) });
  }
}
</script>
