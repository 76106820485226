<template>
  <div class="gallery bg-lol-purple text-white min-h-screen">
    <main class="container mx-auto px-4 py-16">
      <h1 class="text-4xl font-bold text-center mb-12">Our Gallery</h1>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        <div v-for="i in totalImages" :key="i" class="gallery-item">
          <img 
            :src="`/images/gallery/image${i}.jpg`" 
            :alt="`Gallery image ${i}`"
            class="w-full h-64 object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105 cursor-pointer"
            @click="openLightbox(i)"
          >
        </div>
      </div>
    </main>

    <!-- Lightbox -->
    <div v-if="lightboxOpen" class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div class="relative max-w-4xl mx-auto">
        <img 
          :src="`/images/gallery/image${currentImage}.jpg`" 
          :alt="`Gallery image ${currentImage}`"
          class="max-h-[80vh] w-auto"
        >
        <button @click="closeLightbox" class="absolute top-4 right-4 text-white text-2xl">&times;</button>
        <button @click="prevLightboxImage" class="absolute left-4 top-1/2 text-white text-4xl">&lt;</button>
        <button @click="nextLightboxImage" class="absolute right-4 top-1/2 text-white text-4xl">&gt;</button>
      </div>
    </div>
  </div>
</template>

<script>
import enhancedSeoMixin from '@/mixins/enhancedSeoMixin'

export default {
  name: 'Gallery',
  mixins: [enhancedSeoMixin],
  mounted() {
    this.setEnhancedMetaTags({
      title: 'Photo Booth Gallery | Event Photos Melbourne | LOL Photo Booth',
      description: 'View our gallery of amazing photo booth moments from weddings, parties and events across Melbourne. See how our photo booths create unforgettable memories.',
      keywords: 'photo booth gallery, event photos melbourne, wedding photos, party photos, photo booth examples, melbourne events gallery',
      image: '/images/gallery-preview.jpg'
    })
  },
  data() {
    return {
      lightboxOpen: false,
      currentImage: 1,
      totalImages: 12
    }
  },
  methods: {
    openLightbox(imageIndex) {
      this.currentImage = imageIndex;
      this.lightboxOpen = true;
    },
    closeLightbox() {
      this.lightboxOpen = false;
    },
    prevLightboxImage() {
      this.currentImage = this.currentImage > 1 ? this.currentImage - 1 : this.totalImages;
    },
    nextLightboxImage() {
      this.currentImage = this.currentImage < this.totalImages ? this.currentImage + 1 : 1;
    }
  }
}
</script>

<style scoped>
.gallery-item {
  transition: transform 0.3s ease;
}
.gallery-item:hover {
  transform: scale(1.05);
}
</style>
